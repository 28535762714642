/*
 * @Date: 2023-03-30 20:47:41
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-03 15:56:59
 * @FilePath: \J1-M3-QRP-CODE\components\container\index.js
 */
import React from "react";
import Register from '../register';
import Modal from '../modal';

import DownLoad from "../download";
export default class Container extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            error_text:'',
            isModal :false,
            pageWidth:1024,
        };

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.resize = this.resize.bind(this);
    }

    componentDidMount(){
        this.resize();
        this.screenChange();
        this.ensureBackgroundChanged();
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.resize);
    }

    componentWillReceiveProps(prevProps){
        if(prevProps.bannerList === this.props.bannerList){
            this.resize();
        }
    }

    openCs (){
        var CSLive = global.open(
            'about:blank',
            '_blank',
            'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=500, height=500'
        );
        CSLive.document.title = 'Hỗ Trợ Trực Tuyến JBO';
        CSLive.focus();
        CSLive.location.href = 'https://acaiberryapp.hihi2u.com/acaiberryapp';
    }

    openModal(text){
        this.setState({
            error_text : text,
            isModal : !this.state.isModal
        })
    }

    closeModal(){
        this.setState({
            isModal : false
        })
    }

    _setupCmsImage(pageWidth){
        if(['m3-001'].includes(this.props?.pageName)){return}
        let { bannerList } = this.props;
        if(bannerList.length > 0){
            let Contain = document.getElementsByClassName('container')[0] || '';
            if(!Contain){return}//prevent bug in container class not exsist in m3-005
            console.log('Contain changed.', Contain);
            if(pageWidth > 1024) {
                Contain.style.background = `url(${bannerList[0].cmsImageUrlWeb}) no-repeat`;
            }else{
                Contain.style.background = `url(${bannerList[0].cmsImageUrlMobile}) no-repeat   `;
            }
            Contain.style.backgroundSize = '100%';
        }
    }

    resize(){
        this.setState({
            pageWidth : document.body.clientWidth || 1024,
        },()=>{
           !this.props.changeTheme  && this._setupCmsImage(this.state.pageWidth);
        })
    }

    /**
     * 监听屏幕尺寸变化
     */
    screenChange(){
        window.addEventListener('resize',this.resize);
    }

    ensureBackgroundChanged(){ // fix for CXCMS-178
        var t = setInterval(() => {
            this._setupCmsImage(document.body.clientWidth || 1024);
            let Contain = document.getElementsByClassName('container')[0] || '';
            if (!Contain) {
                clearInterval(t);
                // console.log('cleared Interval');
            } else {
                if (Contain.style.background.includes('url')) {
                    clearInterval(t);
                    // console.log('cleared Interval');
                }
            }
        }, 400);
    }

    render(){
        const {addAffiliateParamToUrl} = this.props;
        return (
            <div className={(this.props.changeTheme ? "containerV2":"container") + (this.props?.pageName === 'm3-001' ? '  m3-001-container' : '') }>

                <div className="register-qrcode web-register-qrcode">

                    <div className="ant-row">
                         {/* 注册 */}
                        <Register openModal={this.openModal} mainSiteUrl={this.props.mainSiteUrl} changeTheme={this.props.changeTheme} addAffiliateParamToUrl={addAffiliateParamToUrl}></Register>
                        <DownLoad nativeAppUrl={this.props.nativeAppUrl} changeTheme={this.props.changeTheme}></DownLoad>
                    </div>

                    <div className="ant-footer">
                        <img className="footer-left" src="/static/images/m3-001/sponsorships.jpg" />
                        <div className="footer-right">
                            <p className="title">LIÊN HỆ CHÚNG TÔI</p>
                            <p className="cs">
                                <img className="icon" src="/static/images/m3-001/ico-mail.png"/>
                                <span onClick={()=>{

                                }}> cs@jbovn.com </span>
                            </p>

                            <p className="cs" onClick={()=>{
                                this.openCs();
                            }}>
                                <img className="icon" src="/static/images/m3-001/ico-livechat.png" />
                                <span> Live Chat </span>
                            </p>
                        </div>
                    </div>

                </div>


                {/* Mobile ipad 样式 */}
                <div className="mobile-register-qrcode">
                    {/* 注册 */}
                    <Register openModal={this.openModal} mainSiteUrl={this.props.mainSiteUrl} changeTheme={this.props.changeTheme} addAffiliateParamToUrl={addAffiliateParamToUrl}></Register>
                    {/* 下载 */}
                    <DownLoad nativeAppUrl={this.props.nativeAppUrl}></DownLoad>
                </div>



                {/* 弹窗提示 */}
                <Modal error_text={this.state.error_text} closeModal={this.closeModal} isModal={this.state.isModal}></Modal>


            </div>

        )
    }
}