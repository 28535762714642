/*
 * @Date: 2023-03-14 14:07:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-03 19:29:15
 * @FilePath: \J1-M3-QRP-CODE\components\download\index.js
 */
import React from "react";
import { ApiPort } from "../../actions/API";
import { fetchRequest } from "../../config/Fatch.config";
import QRCode from 'qrcode';
import { Button } from "antd";
import { getAffFromCookie } from "../../actions/util";
class DownLoad extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nativeAppUrl : props.nativeAppUrl,
            qrpic:''
        }


        
    }
   
    componentWillReceiveProps(nextProps , prevState){
        if(nextProps.nativeAppUrl !== prevState.nativeAppUrl){
            this.getQrcodeUrl(nextProps.nativeAppUrl);
        }
        return ;
    }
    // static getDerivedStateFromProps(nextProps , prevState){
    //     if(nextProps.nativeAppUrl !== prevState.nativeAppUrl){
    //         console.log(prevState);
    //         return {nativeAppUrl : nextProps.nativeAppUrl};
    //     }
    //     return null;
    // }


    checkURL(url){
        let aff  ;
        let startIndex = 0;
        if (url.indexOf('#') !== -1) {
            startIndex = url.indexOf('#');
        }
    
        if(url.indexOf('?',startIndex) === -1){

            aff = getAffFromCookie() && '?aff='+getAffFromCookie();
            
        }else{
            if(url.includes("?aff")){
                aff = '';
            }else{
                aff = getAffFromCookie() && '&aff='+getAffFromCookie();
            }
        }
        return aff;
    }



    async getQrcodeUrl(nativeAppUrl){
        let aff = this.checkURL(nativeAppUrl);        

        if(typeof nativeAppUrl === 'string' && nativeAppUrl !== ''){
            let database = await QRCode.toDataURL(nativeAppUrl + aff);
            this.setState({
                qrpic : database
            })
        }
    }


    




    render(){
        let { qrpic } = this.state;
        let { nativeAppUrl } = this.props;
        
        return (
            <>
                <div className="register-box web-qrcode">
                    <div className="box-download-title"></div>

                    <div className="qrcode-content">
                        <img alt="qrcode" style={{width:'9rem',marginRight:'14%' , marginLeft:'7%'}} src={qrpic} />
                        <div className="Down">
                            {/*  */} 
                            <p className="textqrpdow">+88K FREE Nạp Tiền Qua App</p>
                        </div>
                    </div>

                   
                    
                    <div className="btnDownload">

                        {/* Android 点击下载 */}
                        <img className="icon" src={this.props.changeTheme ? "/static/images/m3-001/LQRP_ANDROID_BTN.png":"/static/images/m3-001/button-android.png"}  onClick={()=>{
                            global.location.href = this.props.nativeAppUrl + this.checkURL(this.props.nativeAppUrl);
                        }}/>
                         
                        {/* IOS 点击下载 */}
                        <img className="icon"  src={this.props.changeTheme ? "/static/images/m3-001/LQRP_APPLE_BTN.png":"/static/images/m3-001/button-ios.png"} onClick={()=>{
                            global.location.href = this.props.nativeAppUrl + this.checkURL(this.props.nativeAppUrl);
                        }} />
                    </div>
                </div>


                {/*  Phone Ipad */}

                <div className="register-box mobile-qrcode">
                    <img src="/static/images/m3-001/dangki.png" />
                    <img className="down-banner" src="/static/images/m3-001/u3.png" />
                    <div className="downText" onClick={()=>{
                        global.location.href = this.props.nativeAppUrl + this.checkURL(this.props.nativeAppUrl);
                    }}>
                        <a>TẢI VỀ ỨNG DỤNG</a>
                    </div>
                    
                </div>

            </>
        )
    }
}


export default DownLoad;